import { Button, Grid, MenuItem, Paper, Tooltip,
         Table, TableBody, TableContainer, TableHead, TablePagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toastr from "toastr";
import { InfoTooltip, ListTableCell, ListTableRow, SearchTableCell } from "../../../components/CustomStyles/StyledComponents";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { deleteAdminAccount, getAllAdminAccounts, getUserRoles, resetResponseState, setLoading, setSelectedUserId, unsetLoading } from "../../../store/appAction";
import DeleteIcon from '@mui/icons-material/Delete';
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import EmptyTableBody from "../../../components/Tables/EmptyTableBody";
import { debounce } from "lodash";
import SortButton from "../../../components/Buttons/SortButton";
import TableSearchText from "../../../components/Inputs/TableSearchText";
import TableSearchDatePicker from "../../../components/Inputs/TableSearchDatePicker";
import TableSearchSelect from "../../../components/Inputs/TableSearchSelect";
import { UserStatus } from "../../../helpers/userHelper"

const SORTING = 'fullName';
const SORT_ORDER = 'asc';
const PAGE = 0;
const ROWS_PER_PAGE = 10;

const AdminAccounts = () => {
    dayjs.extend(utc);
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const accountsList = useSelector(state => state.adminAccount.accounts);
    const userRoles = useSelector(state => state.common.userRoles);
    const permissions = useSelector(state => state.auth.permissions);

    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState({
        sorting: SORTING,
        sortOrder: SORT_ORDER,
        skipCount: PAGE,
        maxResultCount: ROWS_PER_PAGE
    });
    const [pagination, setPagination] = useState({
        page: PAGE,
        rowsPerPage: ROWS_PER_PAGE
    });
    const [skip, setSkip] = useState(false);

    const menuOpen = Boolean(anchorEl);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getUserRoles());
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        accountsList && setTimeout(() => {
            setSkip(false);
            dispatch(unsetLoading());
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsList]);

    useEffect(() => {
        const { page, rowsPerPage } = pagination;

        !skip && accountsList?.items && setSearchQuery(prevState => ({
            ...prevState,
            skipCount: page * rowsPerPage,
            maxResultCount: rowsPerPage
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
        dispatch(getAllAdminAccounts(searchQuery));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getAllAdminAccounts(searchQuery));
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleEditDetails = (e, userId) => {
        //handleMenuClose();
        dispatch(setSelectedUserId(userId));
        navigate('/admin/account/edit')
    }

    const handleDelete = (event, userId) => {
        dispatch(setSelectedUserId(userId));
        setAnchorEl(null);
        setDeleteModalOpen(true);
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteAdminAccount());
            dispatch(getAllAdminAccounts());
        }
    }

    const handelCreateUser = () => {
        setSelectedUserId('');
        navigate('/admin/account/create')
    }

    const handleChangePage = (event, newPage) => {
        setPagination(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination(prevState => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };

    const setSorting = ({ sortOrder, sorting }) => {
        setSearchQuery(prevState => ({
            ...prevState,
            sortOrder, sorting
        }));
    }

    const buildSearchQuery = (key, value) => {
        setSkip(true);
        setPagination({
            page: 0,
            rowsPerPage: 10
        });
        setSearchQuery(prevState => ({
            ...prevState,
            [key]: value || undefined,
            skipCount: PAGE,
            maxResultCount: ROWS_PER_PAGE
        }));
        // dispatch(setLoading());
    };

    const debouncedBuildSearchQuery = useMemo(() => {
        return debounce(buildSearchQuery, 500);
    }, []);

    // useEffect(() => {
    //     return () => {
    //         debouncedBuildSearchQuery.cancel();
    //     };
    // });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                    {
                    Object.values(permissions ?? {}).some(value => ['AgreCalc.Users', 'AgreCalc.Users.Create'].includes(value)) && 
                        <Button
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            onClick={handelCreateUser}>
                                Create
                        </Button>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '95%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <ListTableCell sx={{ textAlign: 'left', width: '22%' }}>User
                                    <SortButton
                                        sortKey={'fullName'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ width: '22%' }}>Email
                                    <SortButton
                                        sortKey={'email'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ width: '10%' }}>Type
                                    {/* <SortButton
                                        sortKey={'roleNames'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /> */}
                                </ListTableCell>
                                <ListTableCell sx={{ width: '16%' }}>Organisation
                                    <SortButton
                                        sortKey={'organisation'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ width: '12%' }}>Created On
                                    <SortButton
                                        sortKey={'creationTime'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'center', width: '6%' }}>Action</ListTableCell>
                            </TableHead>
                            <TableBody>
                                <ListTableRow>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="fullName"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                            autoFocus={true}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="email"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchSelect
                                            searchKey="roleNames"
                                            handleSearchQuery={debouncedBuildSearchQuery}>
                                            {userRoles.map((option) => (
                                                <MenuItem key={option.name} value={option.name}>{option?.extraProperties?.DisplayName}</MenuItem>
                                            ))}
                                        </TableSearchSelect>
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="organisation"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchDatePicker
                                            searchKey="creationTime"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                </ListTableRow>
                                {accountsList?.items?.length > 0 ?
                                    (accountsList?.items || []).map((row, index) => (
                                        <ListTableRow key={row.id}>
                                            <ListTableCell sx={{ cursor: 'pointer', width: '22%' }} onClick={(e) => handleEditDetails(e, row.id)}>
                                                <Tooltip title={UserStatus.getName(row?.isActive)}>
                                                    <span style={{color:UserStatus.getIconColor(row?.isActive)}} >
                                                        {UserStatus.getIcon(row?.isActive)+' '}
                                                    </span>
                                                </Tooltip>
                                                {row?.fullName || ''}
                                            </ListTableCell>
                                            <ListTableCell sx={{ width: '22%' }}>{row?.email || ''}</ListTableCell>
                                            <ListTableCell sx={{ width: '10%' }}>{row?.roleNames?.[0] || ''} </ListTableCell>
                                            <ListTableCell sx={{ width: '16%' }}>{row?.organisation || ''}</ListTableCell>
                                            <ListTableCell sx={{ width: '12%' }}>{dayjs.utc(row?.creationTime || '').local().format('DD MMM YYYY')}</ListTableCell>
                                            <ListTableCell sx={{ textAlign: 'center', width: '6%' }}>
                                                <InfoTooltip title="Delete User">
                                                    <DeleteIcon id='delete' sx={{ cursor: 'pointer' }} aria-controls={menuOpen} 
                                                     onClick={(e) => handleDelete(e, row.id)} />
                                                </InfoTooltip>
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))
                                    :
                                    <EmptyTableBody />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={accountsList?.totalCount}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <DeleteModal
                    isOpen={deleteModalOpen}
                    handleResponse={handleDeleteModalResponse}
                    value={'Are you sure you want to delete this User?'} />
            </Grid>
        </Grid >
    );
}

export default AdminAccounts

import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Link, Menu, } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link as RouterLink, } from 'react-router-dom';
import { InfoTooltip } from '../../components/CustomStyles/StyledComponents';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { SUPER_ADMIN } from '../../constants/userRoles';

const MENU_ITEMS = [
  { text: 'Contact Us', path: '/contact-us' },
  { text: 'Release Notes', path: '/release-notes' },
  { text: 'Knowledge Base', path: '/knowledge-base' },
  { text: 'Suggest Ideas', path: '/suggest-ideas' },
  { text: 'Terms & Conditions', path: '/terms-and-conditions' },
  { text: 'Site Admin', path: '/admin/site-admin' },
];

const AboutMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const user = useSelector(state => state.auth.user);
  const handleSubMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InfoTooltip title="About Agrecalc">
        <IconButton
          onClick={handleSubMenuClick}
          color="inherit"
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreHorizIcon />
        </IconButton>
      </InfoTooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleSubMenuClose}
        onClick={handleSubMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top', }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom', }}
      >
        {MENU_ITEMS.map((item, index) => (
          <MenuItem key={index}>
            {item.text === 'Site Admin' && (user?.role?.includes(SUPER_ADMIN) || user?.role === 'superAdmin') ? (
              <Link underline="none" sx={{ color: 'inherit' }} component={RouterLink} to={item.path}>
                {item.text}
              </Link>
            ) : (
              item.text !== 'Site Admin' && (
                <Link target="_blank" underline="none" sx={{ color: 'inherit' }} component={RouterLink} to={item.path}>
                  {item.text}
                </Link>
              )
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default AboutMenu;

import { ADMIN, CONSULTANT, FARMER, SUPER_ADMIN } from '../constants/userRoles';

export const UserStatus = Object.freeze({
    userStatus : [
        {statusName:'Active',value:true,icon:'\u{2705}',color:'green'},
        {statusName:'Inactive',value:false, icon:'\u{26D4}',color:'red'}
    ],
    getIcon: (status) => {
        return UserStatus.userStatus.find( (s)=> s.value===status)?.icon || '\u{26A0}';
    },
    getIconColor: (status) =>{
        return  UserStatus.userStatus.find( (s)=> s.value===status)?.color || 'red';
    },
    getName: (status) =>{
        return  UserStatus.userStatus.find( (s)=> s.value===status)?.statusName || 'Error';
    }
});


export const UserRole = Object.freeze({
    isFarmer: (role) => {return role===FARMER},
    isConsultant: (role) => {return role===CONSULTANT},
    isAdmin: (role) => {return role===ADMIN},
    isSuperAdmin: (role) => {return role===SUPER_ADMIN},
    isAllAdmins: (role) => {return [ADMIN, SUPER_ADMIN].includes(role) },
    isConsultantOrAdmin: (role) => {return [ADMIN, SUPER_ADMIN, CONSULTANT].includes(role) },
});


//Pages
import FarmReports from '../pages/FarmReports';
import Charts from '../pages/Dashboard/Charts';
import FarmReport from '../pages/DataEntry/FarmReport';
import LandCrops from '../pages/DataEntry/LandCrops/LandCrops';
import Fertiliser from '../pages/DataEntry/LandCrops/Fertiliser';
import OtherFeatures from '../pages/OtherFeatures';
import Pesticides from '../pages/DataEntry/LandCrops/Pesticides';
import CropProduction from '../pages/DataEntry/LandCrops/CropProduction';
import CropUseLivestock from '../pages/DataEntry/LandCrops/CropUseLivestock';
import Biochar from '../pages/DataEntry/LandCrops/Biochar';
import SoilCarbon from '../pages/DataEntry/LandCrops/SoilCarbon';
import OrganicMaterialManures from '../pages/DataEntry/LandCrops/OrganicMaterialManures';
import TransferredManure from '../pages/DataEntry/LandCrops/TransferredManure';
import NumbersWeights from '../pages/DataEntry/LiveStock/NumbersWeights';
import MonthlyStockTool from '../pages/DataEntry/LiveStock/MonthlyStockTool';
import PurchasesSalesDeaths from '../pages/DataEntry/LiveStock/PurchasesSalesDeaths';
import Performance from '../pages/DataEntry/LiveStock/Performance';
import ManureManagement from '../pages/DataEntry/LiveStock/ManureManagement';
import Feed from '../pages/DataEntry/LiveStock/Feed';
import Bedding from '../pages/DataEntry/LiveStock/Bedding';
import FeedRation from '../pages/DataEntry/LiveStock/FeedRation';
import ElectricityFuel from '../pages/DataEntry/EnergyWaste/ElectricityFuel';
import ElectricityFuelCont from '../pages/DataEntry/EnergyWaste/ElectricityFuelCont';
import RenewableElectricity from '../pages/DataEntry/EnergyWaste/RenewableElectricity';
import RenewableHeat from '../pages/DataEntry/EnergyWaste/RenewableHeat';
import WasteTransportWater from '../pages/DataEntry/EnergyWaste/WasteTransportWater';
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import WholeFarmTables from '../pages/Results/WholeFarm/WholeFarmTables';
import WholeFarmCharts from '../pages/Results/WholeFarm/WholeFarmCharts';
import WholeFarmPreview from '../pages/Results/PDF/WholeFarm/Preview';
import FarmerFriendlyPreview from '../pages/Results/PDF/FarmerFriendly/Preview';
import EnterpriseTables from '../pages/Results/Enterprise/EnterpriseTables';
import EnterpriseCharts from '../pages/Results/Enterprise/EnterpriseCharts';
import EnterprisePreview from '../pages/Results/PDF/Enterprise/Preview';
import MyFarm from '../pages/ProfileMenu/MyFarm';
import Accounts from '../pages/Admin/Account/Accounts'
import Account from '../pages/Admin/Account/Account'
import Farm from '../pages/Admin/Farm/Farm';
import Farms from '../pages/Admin/Farm/Farms';
import Reports from '../pages/Admin/Report/Reports';
import Organisations from '../pages/Admin/Organisation/Organisations';
import Organisation from '../pages/Admin/Organisation/Organisation';
import Offices from '../pages/Admin/Office/Offices';
import MyAccount from '../pages/ProfileMenu/MyAccount';
import Office from '../pages/Admin/Office/Office';
import ReleaseNotes from '../pages/Static/ReleaseNotes';
import ContactUs from '../pages/Static/ContactUs';
import SuggestIdeas from '../pages/Static/SuggestIdeas';
import KnowledgeBase from '../pages/Static/KnowledgeBase';
import TermsAndConditions from '../pages/Static/TermsAndConditions';
import ResetPassword from '../pages/Authentication/ResetPassword';
import Projects from '../pages/Admin/Project/Projects';
import Project from '../pages/Admin/Project/Project';
import ProjectInvitation from '../pages/Static/ProjectInvitation';
import YearOnYear from '../pages/YearOnYear/YearOnYear';
import ArableRotation from '../pages/DataEntry/SoilCarbon/ArableRotation';
import Grassland from '../pages/DataEntry/SoilCarbon/Grassland';
import SoilProperties from '../pages/DataEntry/SoilCarbon/SoilProperties';
import SiteAdmin from '../pages/Admin/SiteAdmin/SiteAdmin';

import { ADMIN, CONSULTANT, SUPER_ADMIN } from '../constants/userRoles';

const publicRoutes = [
  { path: 'auth/login', component: Login },
  { path: 'auth/register', component: Register },
  { path: 'auth/forgot-password', component: ForgotPassword },
  { path: 'auth/reset-password', component: ResetPassword },

  { path: '/release-notes', component: ReleaseNotes },
  { path: '/contact-us', component: ContactUs },
  { path: '/suggest-ideas', component: SuggestIdeas },
  { path: '/knowledge-base', component: KnowledgeBase },
  { path: '/terms-and-conditions', component: TermsAndConditions },
]
const privateRoutes = [
  { path: '/project/accept-invite/:code', component: ProjectInvitation },

  { path: '/', component: FarmReports },
  { path: '/farm-reports/create', component: FarmReport },
  { path: '/farm-reports/list', component: FarmReports },
  { path: '/data-entry/report/:mode', component: FarmReport },

  { path: '/data-entry/land-crops/land-area-crops', component: LandCrops },
  { path: '/data-entry/land-crops/fertiliser-lime', component: Fertiliser },
  { path: '/data-entry/land-crops/organic-material-manures', component: OrganicMaterialManures },
  { path: '/data-entry/land-crops/transferred-manure', component: TransferredManure },
  { path: '/data-entry/land-crops/pesticides', component: Pesticides },
  { path: '/data-entry/land-crops/crop-production-use', component: CropProduction },
  { path: '/data-entry/land-crops/crop-use-livestock', component: CropUseLivestock },
  { path: '/data-entry/land-crops/biochar', component: Biochar },

  { path: '/data-entry/livestock/numbers-weights', component: NumbersWeights },
  { path: '/data-entry/livestock/purchases-sales-deaths', component: PurchasesSalesDeaths },
  { path: '/data-entry/livestock/performance', component: Performance },
  { path: '/data-entry/livestock/manure-management', component: ManureManagement },
  { path: '/data-entry/livestock/feed', component: Feed },
  { path: '/data-entry/livestock/bedding', component: Bedding },
  { path: '/data-entry/livestock/feed-ration', component: FeedRation },
  { path: '/data-entry/livestock/monthly-stock-tool', component: MonthlyStockTool },

  { path: '/data-entry/soil-carbon/arable-rotation', component: ArableRotation },
  { path: '/data-entry/soil-carbon/grassland', component: Grassland },
  { path: '/data-entry/soil-carbon/soil-properties', component: SoilProperties },

  { path: '/data-entry/energy-waste/electricity-fuel', component: ElectricityFuel },
  { path: '/data-entry/energy-waste/electricity-fuel-cont', component: ElectricityFuelCont },
  { path: '/data-entry/energy-waste/renewable-electricity', component: RenewableElectricity },
  { path: '/data-entry/energy-waste/renewable-heat', component: RenewableHeat },
  { path: '/data-entry/energy-waste/waste-transport-water', component: WasteTransportWater },

  { path: '/results/whole-farm/charts', component: WholeFarmCharts },
  { path: '/results/whole-farm/tables', component: WholeFarmTables },
  { path: '/results/whole-farm/report-preview', component: WholeFarmPreview },
  { path: '/results/farmer-friendly/report-preview', component: FarmerFriendlyPreview },
  { path: '/results/results/year-on-year', component: WholeFarmCharts },
  { path: '/year-on-year', component: YearOnYear },

  { path: '/results/enterprise/charts', component: EnterpriseCharts },
  { path: '/results/enterprise/tables', component: EnterpriseTables },
  { path: '/results/enterprise/report-preview', component: EnterprisePreview },

  { path: '/other-features', component: OtherFeatures },

  { path: '/my-farm', component: MyFarm },
  { path: '/my-account', component: MyAccount },

  { path: '/dashboard', component: Charts },
  { path: '/dashboard/charts', component: Charts },
]

const adminRoutes = [
  { path: '/admin/farm/list', component: Farms, },
  { path: '/admin/farm/:mode', component: Farm, },

  { path: '/admin/reports/list', component: Reports, },

  { path: '/admin/account/list', component: Accounts, },
  { path: '/admin/account/:mode', component: Account, },

  { path: '/admin/organisations/list', component: Organisations, },
  { path: '/admin/organisations/:mode', component: Organisation, },

  { path: '/admin/offices/list', component: Offices,},
  { path: '/admin/offices/:mode', component: Office,},

  { path: '/admin/project/list', component: Projects, },
  { path: '/admin/project/:mode', component: Project, },
  { path: '/admin/site-admin', component: SiteAdmin, },
]

export { publicRoutes, privateRoutes, adminRoutes }
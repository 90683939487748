import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ErrorModal = ({ isOpen = false, handleResponse, title, children }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Modal
            open={open}
            onClose={() => { handleResponse(false); }}
            aria-labelledby="errormodal-title"
            aria-describedby="errormodal-description">
            <Box sx={style}>
                <Stack spacing={2} sx={{ marginLeft: 'auto' }}>
                    <Typography id="errormodal-title" variant="h6" component="h2" color='red'>
                        {title}
                    </Typography>
                    <Typography id="errormodal-description" variant="body1" component="h2" sx={{ marginBottom: 2 }}>
                        {children}
                    </Typography>
                    <Button variant="contained" size="medium" sx={{ textTransform: 'none' }} onClick={() => handleResponse(true)}>
                        Close
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

ErrorModal.propTypes = {
    isOpen: PropTypes.bool,
    handleResponse: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])

}
export default ErrorModal
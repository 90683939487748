import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";
import toastr from "toastr";
import _ from 'lodash';
import {
    createAdminFarm,
    getAdminFarm, getAllCountries, getAllReports, getOrganisationData,
    resetResponseState, setAdminFarmId, setLoading, unsetLoading, updateAdminFarm
} from '../../../store/appAction';
import { toastrCustomOptions, toastrCustomOptionsLonger, toastrCustomOptionsNoFarmSelectPopUp } from '../../../constants/toastrOptions';
import { ThemeProvider } from '@emotion/react';
import { FormHeaderPaper, FormTitleTypography, MyFarmPaper } from '../../../components/CustomStyles/StyledComponents';
import { mainTheme } from '../../../theme';
import { Grid } from '@mui/material';
import FarmForm from '../../../components/Forms/FarmForm';
import { useParams } from 'react-router-dom';
import { ukPostCodeAlt } from '../../../constants/regex';
import { CONSULTANT } from '../../../constants/userRoles';
import { EDIT } from '../../../constants/modes';

const Farm = () => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let { mode } = useParams();

    const user = useSelector(state => state.auth.user);
    const farmDetails = useSelector(state => state.adminFarm.farmDetails);
    const farmId = useSelector(state => state.adminFarm.farmId);
    const { countries, successMsg, errorMsg } = useSelector(state => state.common);

    const [intFarmDetails, setIntFarmDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);

    const validationSchema = Yup.object({
        farmName: Yup.string("Enter first name").trim().required("First name is required"),
        officeId: Yup.string("").trim(),
        organisationId: Yup.string("").trim(),
        farmOwnerUserId: Yup.string("").trim().required("Farm Owner is required"),
        holdingNumber: Yup.string("").trim().required("Holding Number is required"),
        addressLine1: Yup.string("").trim(),
        addressLine2: Yup.string("").trim(),
        countryId: Yup.string("").trim().required("Country is required"),
        townCity: Yup.string("").trim().required("Town/City is required"),
        zipPostCode: Yup.string("").trim().required("Postcode is required")
            .when('countryId', {
                is: (countryId) => (_.find(countries, { id: countryId })?.countryCategory || -1) === 1,
                then: Yup.string().matches(ukPostCodeAlt, 'Enter a valid postcode'),
            }),
        businessReferenceNumber: Yup.string("").trim().required("Business Reference Number is required")
    });

    useEffect(() => {
        if (mode === EDIT && farmId != null) {
            dispatch(setLoading());
            dispatch(getAdminFarm(farmId));
        }
        else {
            dispatch(setAdminFarmId(null));
            setFormData({
                id: '',
                farmName: '',
                farmOwnerUserId: '',
                holdingNumber: '',
                organisationId: user.role === CONSULTANT ? user?.organisationId : '',
                officeId: '',
                addressLine1: '',
                addressLine2: '',
                townCity: '',
                countryId: '',
                zipPostCode: '',
                businessReferenceNumber: '',
                farmAdvisoryService: false,
                license: 1,
                initialFarmOwner:''
            });
        }
        dispatch(getAllCountries());
        dispatch(getOrganisationData());
        dispatch(getAllReports({ farmId }));
        return () => {
            setIntFarmDetails(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            successMsg && toastr.success(successMsg);
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        if (intFarmDetails && !_.isEmpty(intFarmDetails)) {
            if(!intFarmDetails?.isUpgraded){
                toastr.warning("This farm cannot be updated as it belongs to a user that has not been approved for Agrecalc cloud.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
            }
            setFormData({
                id: intFarmDetails?.id || '',
                farmName: intFarmDetails?.farmName || '',
                farmOwnerUserId: intFarmDetails?.farmOwnerUserId || '',
                holdingNumber: intFarmDetails?.holdingNumber || '',
                organisationId: intFarmDetails?.organizationId || '',
                officeId: intFarmDetails?.officeId || '',
                addressLine1: intFarmDetails?.addressLine1 || '',
                addressLine2: intFarmDetails?.addressLine2 || '',
                townCity: intFarmDetails?.townCity || '',
                countryId: intFarmDetails?.countryId || '',
                zipPostCode: intFarmDetails?.zipPostCode || '',
                businessReferenceNumber: intFarmDetails?.businessReferenceNumber || '',
                farmAdvisoryService: intFarmDetails?.farmAdvisoryService || false,
                license: intFarmDetails?.license,
                isUpgraded: intFarmDetails?.isUpgraded,
                initialFarmOwner: intFarmDetails?.farmOwner || ''
            });
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intFarmDetails]);


    useEffect(() => {
        setIntFarmDetails(farmDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [farmDetails])


    const handleSubmit = (data) => {
        dispatch(setLoading());
        if (mode === EDIT && farmId != null) {
            dispatch(updateAdminFarm(data));
        } else {
            dispatch(createAdminFarm(data));
        }
    }

    return (
        <ThemeProvider theme={mainTheme}>
            <MyFarmPaper>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormHeaderPaper sx={{ marginTop: 1.5, padding: 2 }}>
                            <FormTitleTypography variant="h5" gutterBottom component="div">
                                Farm Account Details
                            </FormTitleTypography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid textAlign={'right'} item xs={4}>
                        {(mode === EDIT && farmId != null) &&
                            <FormTitleTypography sx={{ margin: 1.5, padding: 2 }} variant="h5" gutterBottom component="div">
                                Farm ID : {intFarmDetails?.friendlyFarmId}
                            </FormTitleTypography>}
                    </Grid>
                </Grid>
                {formData &&
                    <Formik
                        enableReinitialize
                        render={props => <FarmForm {...props} role={user?.role} mode={mode} isMyFarm={false} />}
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    />
                }
            </MyFarmPaper>
        </ThemeProvider>
    )
}

export default Farm
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, IconButton, Menu, MenuItem, Paper, Typography, 
  Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListTableCell, ListTableRow, FormTitleTypography, FormHeaderPaper, FormDescriptionTypography, NoMaxWidthTooltip} from '../../components/CustomStyles/StyledComponents';
import { copyReport, deleteReport, getAllReports, resetResponseState, setLoading, setReportId, submitReviewReport, submitStatusReport, 
         unsetLoading,getAdminFarm,  clearAllReportsData, getUserPermissionsForFarm } from '../../store/appAction';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toastr from "toastr";
import DeleteReportModal from "../../components/Modal/DeleteReportModal";
import CopyReportModal from "../../components/Modal/CopyReportModal";
import { toastrCustomOptions, toastrCustomOptionsLonger,toastrCustomOptionsNoFarmSelectPopUp } from "../../constants/toastrOptions";
import { REPORT_STATUS } from "../../constants/arrays";
import { UserRole } from '../../helpers/userHelper';
import CommonModal from "../../components/Modal/CommonModal";
import ErrorModal from "../../components/Modal/ErrorModal";
import EmptyTableBody from "../../components/Tables/EmptyTableBody";
import SelectedFarm from '../../components/SelectedFarm/SelectedFarm';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import {ReportStatus, ReportHlp} from '../../helpers/reportResultsHelper'
import {AGCReportsPermissions} from '../../helpers/permissionsHelper'

const FarmReport = () => {
  dayjs.extend(utc);
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const farmId = useSelector(state => state.common.farmId);
  const reportList = useSelector(state => state.farm.reports);
  const adminFarm = useSelector(state => state.adminFarm);
  const { user, successMsg, errorMsg } = useSelector(state => state.common);
  const selectedFarmId = useSelector(state => (UserRole.isFarmer(user.role) ?
    state.common.farmId : state.adminFarm.farmId));
  const permissions = useSelector(state => state.auth.permissions);
  const farmPermissions = useSelector(state => state.auth.farmPermissions);

  const [currentReport, setCurrentReport] = useState(null);
  const [copyReportTitle, setCopyReportTitle] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [commonModalOpen, setCommonModalOpen] = useState(false);
  const [cantValidateModalOpen, setCantValidateModalOpen] = useState(false);
  const [alreadyValidated, setAlreadyValidated] = useState(null)


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFarmName, setSelectedFarmName] = useState(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    console.log("farmId",farmId)
    if(UserRole.isFarmer(user.role)){
      getFarmNameForFarmer(farmId);
    }
    dispatch(setLoading());
    dispatch(getUserPermissionsForFarm({ farmId }));
    dispatch(getAllReports({ farmId }));
    // dispatch(setReportId(null));
    return () => {
    }
  }, [farmId]);

  useEffect(() => {
    return () => {
      dispatch(clearAllReportsData());
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);
  }, [reportList]);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      if (successMsg) {
        toastr.success(successMsg);
        dispatch(getUserPermissionsForFarm({ farmId }));
        dispatch(getAllReports({ farmId }));
      }
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  const getFarmNameForFarmer = async (farmId) => {
    if(farmId){
      const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
      if (response && response.status === 200) {
        setSelectedFarmName(response.data.farmName);
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClick = (event, report) => {
    setCurrentReport(report)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditDetails = (e, reportId) => {
    if(Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canEdit(value))){
      handleMenuClose();
      dispatch(setReportId(reportId || currentReport?.reportId));
      navigate('/data-entry/report/edit')
    }
  }

  const handleSubmitForReview = (e, reportId) => {
    handleMenuClose();
    console.log(`+++${reportId} -- ${JSON.stringify(currentReport)}`)
    let alreadyValidated = ReportHlp.findSameYearAlreadyValidated(reportList, reportId);
    if(alreadyValidated){
      setAlreadyValidated(alreadyValidated);
      setCantValidateModalOpen(true);
    }else if ( ReportStatus.isPreCalculation(currentReport?.status))
      setCommonModalOpen(true);
    else if (ReportStatus.isCalculated(currentReport?.status))
      dispatch(submitReviewReport(currentReport?.reportId));
  }

  const handleSubmitStatus = (statusId) => {
    handleMenuClose();
    dispatch(submitStatusReport({
      reportId: currentReport?.reportId,
      statusId: statusId
    }));

  }

  const handleViewCharts = () => {
    handleMenuClose();
    dispatch(setReportId(currentReport?.reportId));
    navigate('/results/whole-farm/charts')
  }
  const handleCopy = () => {
    handleMenuClose();
    setCopyModalOpen(true);
    // dispatch(setReportId(currentReport));
  }
  const handleDelete = async (reportId) => {
    if(reportId){
      const response = await api.get(endpoints.farmReport.details.concat('?reportId=', reportId));
      if (response && response.status === 200) {
        if(response?.data?.isUpgraded === false){
          handleMenuClose();
          toastr.warning("This farm report cannot be updated as it belongs to a user that has not been approved for Agrecalc cloud.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
        }else{
          handleMenuClose();
          setDeleteModalOpen(true);
        }
      }
    }   
  }

  const handleDeleteModalResponse = (positiveResponse) => {
    setDeleteModalOpen(false);
    if (positiveResponse) {
      dispatch(deleteReport(currentReport?.reportId));
    }
  }

  const handleCopyModalResponse = (positiveResponse) => {
    setCopyModalOpen(false);
    if (positiveResponse) {
      dispatch(copyReport({ reportId: currentReport?.reportId, title: copyReportTitle }));
    }
  }

  const handleCommonModalResponse = (positiveResponse) => {
    setCommonModalOpen(false);
    if (positiveResponse) {
      dispatch(setReportId(currentReport?.reportId));
      navigate('/results/whole-farm/charts')
    }
  }

  const valStatusOptions=[
    {name:'Complete And Valid', statusId:ReportStatus.ReviewCompletedAndValid},
    {name:'Complete Not Valid', statusId:ReportStatus.ReviewCompletedAndInvalid},
    {name:'Incomplete', statusId:ReportStatus.CalculationCompleted},
  ] 

  const renderStatusTooltip = () => {
    const text = `
    <div><strong>Incomplete</strong> - This report has either not been submitted or has missing information.</div>
    <div><strong>Under Validation</strong> - This report has been submitted and is currently being reviewed for validation.</div>
    <div><strong>Valid (not in benchmarks)</strong> - This report has been completed and not accepted to the benchmarking database.</div>
    <div><strong>Validated</strong> - This report has been completed and accepted to the benchmarking database.</div>
    `;
    return <div dangerouslySetInnerHTML={{__html: text}}/>
  };

  const addTextColor = (cellData) => {
    switch(cellData){
        case 'Incomplete':  return <ListTableCell color='#C94D49'>{cellData}</ListTableCell>  
        case 'Under Validation':  return <ListTableCell color='#EDA710'>{cellData}</ListTableCell> 
        case 'Validated':  return <ListTableCell color='#5ace5d'>{cellData}</ListTableCell>
        case 'Valid (not in benchmarks)':  return <ListTableCell color='#595959'>{cellData}</ListTableCell> 
        default : return <ListTableCell>{cellData}</ListTableCell>
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper sx={{ p: 0, display: 'flex', flexDirection: 'column',}}>
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName || selectedFarmName}/>
          <FormTitleTypography variant="h5" component="div">
            Farm Reports
          </FormTitleTypography>
          <FormDescriptionTypography variant="body2" paragraph>
            {'Your farm reports and their status are presented below.'}
          </FormDescriptionTypography>
        </FormHeaderPaper>
      </Grid>
      <Grid item>
        {
          Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canCreate(value)) ?
            <FormDescriptionTypography variant="body2" paragraph>
              {'You can Edit, View Results, Copy, Delete and change the status of reports using the ellipsis under the Actions column. To build a new report please press the Create button.'}
            </FormDescriptionTypography> :
            <FormDescriptionTypography variant="body2" paragraph>
              {'You can Edit, View Results, Copy, Delete and change the status of reports using the ellipsis under the Actions column.'}
            </FormDescriptionTypography>
        }
      </Grid>
      <Grid item xs={12} md={12} lg={12} mt={0}>
        <Paper
          sx={{
            p: 0,
            flexDirection: 'column',
            textAlign: 'right'
          }}
        >
          {
            Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canCreate(value)) && 
              <Button variant="contained"
              sx={{ textTransform: 'none', mr: 5 }}
              onClick={() => navigate('/farm-reports/create')}
            >
              Create
            </Button>
          }
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <ListTableCell sx={{ textAlign: 'left', width: '12%' }}>Report ID</ListTableCell>
                  <ListTableCell sx={{ textAlign: 'left', width: '32%' }}>Name</ListTableCell>
                  <ListTableCell sx={{ width: '18%' }}>Report End Date</ListTableCell>
                  <ListTableCell sx={{ width: '18%' }}>Updated On</ListTableCell>
                  <ListTableCell sx={{ width: '10%' }}>Status
                    <NoMaxWidthTooltip title={renderStatusTooltip()}>
                      <InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' />
                    </NoMaxWidthTooltip>
                  </ListTableCell>
                  <ListTableCell sx={{ width: '8%' }}>Actions</ListTableCell>
                </TableRow>
              </TableHead>
              {reportList.length > 0 ?
                <TableBody>
                  {reportList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <ListTableRow key={row.reportId}>
                      <ListTableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => handleEditDetails(e, row.reportId)}
                      >
                        {row.friendlyReportId || '--'}
                      </ListTableCell>
                      <ListTableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => handleEditDetails(e, row.reportId)}
                      >
                        {row.reportName || '--'}
                      </ListTableCell>
                      <ListTableCell>{dayjs.utc(row.yearEnd).local().format('MMMM YYYY')}</ListTableCell>
                      <ListTableCell>{dayjs.utc(row.lastUpdated || '').local().format('D/M/YY H:mm')}</ListTableCell>
                      { addTextColor(REPORT_STATUS[row.status]) }
                      <ListTableCell>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={menuOpen ? 'long-menu' : undefined}
                          aria-expanded={menuOpen ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleMenuClick(e, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>

                      </ListTableCell>
                    </ListTableRow>
                  ))}
                  <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button',}}
                    anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}
                    PaperProps={{ style: { width: '180px', },}}>
                      {
                        Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canEdit(value)) && 
                        <MenuItem onClick={handleEditDetails}>
                          Edit
                        </MenuItem>
                      }
                      {ReportStatus.canSubmit(currentReport?.status) && Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canSubmit(value)) &&
                        <MenuItem onClick={(e)=>handleSubmitForReview(e,currentReport?.reportId)}>
                          Send for Validation
                        </MenuItem>}
                       {UserRole.isAllAdmins(user?.role) && ReportStatus.isUnderReview(currentReport?.status)?     
                            valStatusOptions.map((item) => (
                              <MenuItem key={item.statusId} onClick={() => handleSubmitStatus(item.statusId)}>
                               {item.name}
                              </MenuItem>
                            )):''            
                        }
                      <MenuItem onClick={handleViewCharts}>
                        View Results
                      </MenuItem>
                      {
                        Object.values(farmPermissions ?? {}).some(value => AGCReportsPermissions.canCopy(value)) && 
                        <MenuItem onClick={handleCopy}>
                          Copy
                        </MenuItem>
                      }
                      <MenuItem onClick={()=>{ handleDelete(currentReport?.reportId);}} 
                              disabled={currentReport?.status===ReportStatus.ReviewCompletedAndValid} >
                        Delete
                      </MenuItem>
                  </Menu>
                </TableBody> : <TableBody><EmptyTableBody title="reports" /></TableBody>}
            </Table>
          </TableContainer>
          <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={reportList.length}
            rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
          <DeleteReportModal isOpen={deleteModalOpen} handleResponse={handleDeleteModalResponse} />
          <CopyReportModal isOpen={copyModalOpen} handleResponse={handleCopyModalResponse} onTitleChange={setCopyReportTitle} />
          <CommonModal isOpen={commonModalOpen}
            value='The data for this report is not yet ready for calculation. Press Ok and you will be shown which data checks are failing.'
            handleResponse={handleCommonModalResponse} />
          <ErrorModal isOpen={cantValidateModalOpen} handleResponse={()=>{setCantValidateModalOpen(false);}} title="Submission Error">
              <div>Can only validate one report per year.</div>
              Report {alreadyValidated?.friendlyReportId}: {alreadyValidated?.reportName} has
              already been validated for year {ReportHlp.getYearFromStr(alreadyValidated?.yearEnd)}.
          </ErrorModal>
        </Paper>
      </Grid>
    </Grid >);
};

export default FarmReport;


import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {throttle} from 'lodash';
import { Tooltip } from '@mui/material';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';

export const TooltipUserEmail = (props) => {
    const {userid, wait, children, ...otherprops} = props;

    const [userAccountDetails, setUserAccountDetails] = useState(null);

    const getUserAccountData = async (accountId) => {
        if(accountId){
            const response = await api.get(endpoints.admin.user.account.concat(accountId));
            if (response && response.status === 200) {
                setUserAccountDetails(response.data);
            }
            else {
                setUserAccountDetails(null);
            }    
        }
        else {
            setUserAccountDetails(null);
        }
    };
    const tooltipClose =  () => {
        setUserAccountDetails(null);
    }

    return (
        <Tooltip title={userAccountDetails?.email?userAccountDetails?.email : ''} 
            onOpen={ throttle(() => { getUserAccountData(userid) }, wait)}
            onClose={tooltipClose} {...otherprops}>
            {props.children}
        </Tooltip>
    )
}

TooltipUserEmail.propTypes = {
    userid: PropTypes.string,
    wait: PropTypes.number,
    otherprops: PropTypes.any,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

TooltipUserEmail.defaultProps = {
    userid: null,
    wait: 400
}

export default TooltipUserEmail;